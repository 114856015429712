import React from "react";

export default function LoadingSpinner() {
    return (
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
            <div className="logo-container">
                <img src={require('./assets/images/logo.png')} alt="RedHat" />
            </div>
        </div>
    )
}