import { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import Countdown from 'react-countdown';

import Spinner from './spinner'
import Card from './card'
import ImageIntro from './assets/images/modal_intro.jpg'

import './assets/styles/App.css'

const uniqueCardsArray = [
	{
		type: "1",
		image: require('./assets/images/card_1.jpg')
	},
	{
		type: "2",
		image: require('./assets/images/card_2.jpg')
	},
	{
		type: "3",
		image: require('./assets/images/card_3.jpg')
	},
	{
		type: "4",
		image: require('./assets/images/card_4.jpg')
	},
	{
		type: "5",
		image: require('./assets/images/card_5.jpg')
	},
	{
		type: "6",
		image: require('./assets/images/card_6.jpg')
	}
]

function shuffleCards(array) {
	const length = array.length;

	for (let i = length; i > 0; i--) {
		const randomIndex = Math.floor(Math.random() * i);
		const currentIndex = i - 1;
		const temp = array[currentIndex];
		array[currentIndex] = array[randomIndex];
		array[randomIndex] = temp;
	}

	return array
}

function App() {
	const [cards, setCards] = useState(
		shuffleCards.bind(null, uniqueCardsArray.concat(uniqueCardsArray))
	)
	const [openCards, setOpenCards] = useState([])
	const [clearedCards, setClearedCards] = useState({})
	const [shouldDisableAllCards, setShouldDisableAllCards] = useState(false)
	const [moves, setMoves] = useState(0)
	const [showModal, setShowModal] = useState(false)
	const [showModalWelcome, setShowModalWelcome] = useState(false)
	const [start, setStart] = useState(false)
	const [init, setInit] = useState(false)
	const [modalSuccess, setModalSuccess] = useState(false)
	const [points, setPoints] = useState(0)
	const [num, setNum] = useState(50)
	const [counter, setCounter] = useState(5)
	const [gameOver, setGameOver] = useState(false)

	const [bestScore, setBestScore] = useState(
		JSON.parse(localStorage.getItem("bestScore")) || Number.POSITIVE_INFINITY
	)

	const timeout = useRef(null)

	const disable = () => {
		setShouldDisableAllCards(true)
	}

	const enable = () => {
		setShouldDisableAllCards(false)
	}

	const checkCompletion = () => {
		if (Object.keys(clearedCards).length === uniqueCardsArray.length) {
			setShowModal(true);
			const highScore = Math.min(moves, bestScore)
			setBestScore(highScore);
			localStorage.setItem("bestScore", highScore)
		}
	}


	const checkPoints = () => {
		console.log(points)

		if (points === 5) {
			setModalSuccess(true)
			setCounter(5)
		}
	}

	const evaluate = () => {
		const [first, second] = openCards

		enable()

		if (cards[first].type === cards[second].type) {
			setPoints(points + 1)

			setTimeout(() => {
				checkPoints()
			}, 100)

			setClearedCards((prev) => ({ ...prev, [cards[first].type]: true }))
			setOpenCards([])
			return
		}

		timeout.current = setTimeout(() => {
			setOpenCards([])
		}, 500)
	}

	const handleCardClick = (index) => {
		if (openCards.length === 1) {
			setOpenCards((prev) => [...prev, index])
			setMoves((moves) => moves + 1)
			disable()
		} else {
			clearTimeout(timeout.current)
			setOpenCards([index])
		}
	}


	useEffect(() => {
		let timeout = null

		if (openCards.length === 2) {
			timeout = setTimeout(evaluate, 300)
		}

		return () => {
			clearTimeout(timeout)
		}
	}, [openCards])

	useEffect(() => {
		checkCompletion()
	}, [clearedCards])

	useEffect(() => {
		setTimeout(() => {
			setShowModalWelcome(true)
		}, 3000)
	}, [])



	const checkIsFlipped = (index) => {
		return openCards.includes(index)
	}

	const checkIsInactive = (card) => {
		return Boolean(clearedCards[card.type])
	}

	const handleRestart = () => {
		setClearedCards({});
		setOpenCards([]);
		setShowModal(false);
		setMoves(0);
		setShouldDisableAllCards(false);

		setCards(shuffleCards(uniqueCardsArray.concat(uniqueCardsArray)));
		setModalSuccess(false)
		setInit(false)
		setStart(false)
		setPoints(0)
		setGameOver(false)
		setNum(5)

		setTimeout(() => {
			setShowModalWelcome(true)
		}, 3000)
	}

	const EndGame = () => {
		setGameOver(true)

		setTimeout(function () {
			handleRestart()

			setTimeout(() => {
				setShowModalWelcome(true)
			}, 3000)
		}, 3000)
	}

	const startTimer = () => {
		setNum(50)
	}

	const showCards = () => {

		for (var i = 0; i < 12; i++) {
			document.getElementById('block').classList.remove('none')

			document.getElementById('card_' + i).classList.add('is-flipped')
		}

		setTimeout(() => {
			for (var i = 0; i < 12; i++) {
				document.getElementById('card_' + i).classList.remove('is-flipped')
			}
		}, 3000)

		setTimeout(() => {
			document.getElementById('block').classList.add('none')
		}, 5000)
	}

	useEffect(() => {
		const timer = setInterval(function () {
			setNum(num - 1);

			if (start && num === 0) {
				EndGame()
			}
		}, 1000)

		return () => {
			clearInterval(timer)
		}
	}, [num])

	useEffect(() => {
		const timer = setInterval(function () {
			setCounter(counter - 1);

			if (modalSuccess && counter === 1) {
				handleRestart()
			}
		}, 1000)

		return () => {
			clearInterval(timer)
		}
	}, [counter])

	const toggleFullSceen = () => {
		if (!document.fullscreenElement) {
			document.documentElement.requestFullscreen();
		}
	};

	const url = new URL(window.location.href);
	if(url.pathname.includes('off'))
		return null

	return (
		<>
			<div id="block" className='none' />
			<div className='container' id="app">


				<div className="App">
					{gameOver
						? <>
							<div className='gameover-container'>
								Fim de jogo
							</div>
						</>
						: <></>
					}

					{start
						? <>
							<div className='reset-button' onClick={() => handleRestart()}>
								Reiniciar
							</div>


							<div className='game-countdown'>
								{!modalSuccess ? gameOver ? '' : num : ''}
							</div>
						</>
						: <>
						</>
					}


					<div className='cards-container'>
						{cards.map((card, index) => {
							return (
								<Card
									key={index}
									card={card}
									index={index}
									isDisabled={shouldDisableAllCards}
									isInactive={checkIsInactive(card)}
									isFlipped={checkIsFlipped(index)}
									onClick={handleCardClick}
								/>
							)
						})}
					</div>

					{!init ?
						<div className='modal-intro' onClick={() => [
							setStart(true),
							startTimer(),
							setShowModalWelcome(false),
							setTimeout(() => {
								setInit(true)
								showCards()
							}, 500),
						]}>
							{!showModalWelcome && !start
								? <>
									<Spinner />
								</>
								: <></>
							}

							<CSSTransition
								in={showModalWelcome}
								timeout={300}
								classNames="alert"
								unmountOnExit
							>
								<div className='modal-container' onClick={() => toggleFullSceen()}>
									<img src={ImageIntro} alt="RedHat" />
								</div>
							</CSSTransition>
						</div> : <></>
					}


					<CSSTransition
						in={modalSuccess}
						timeout={300}
						classNames="alert"
						unmountOnExit
						onClick={() => [
							setTimeout(() => {
								// setModalSuccess(false)
								handleRestart()
							}, 0)
						]}
					>
						<div className='modal-intro'>
							<div className='modal-container'>
								<img src={require('./assets/images/modal_win.jpg')} alt="RedHat" />

								<div className='countdown'>
									{counter}
								</div>
							</div>
						</div>
					</CSSTransition>
				</div>
			</div>
		</>
	)
}

export default App
